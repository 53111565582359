import React from 'react'
import Layout from "../components/layout"
import Footer from '../components/footer'
import { Link } from 'gatsby'
import BgImage from '../images/bg/about-us-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import BiophiliaIcon from '../images/clients/biophilia-icon.png'
import BiophiliaLogo from '../images/clients/biophilia-logo.png'
import TechnomedicsIcon from '../images/clients/technomedics-icon.png'
import TechnomedicsLogo from '../images/clients/technomedics-logo.png'
import AuthenticitiesIcon from '../images/clients/asl-icon.png'
import AuthenticitieslLogo from '../images/clients/asl-logo.png'
import CppGlassIcon from '../images/clients/cppglass-icon.png'
import CppGlasslLogo from '../images/clients/cppglass-logo.png'
import UnicornMetalicsIcon from '../images/clients/unicornmetalics-icon.png'
import UnicornMetalicsLogo from '../images/clients/unicornmetalics-logo.png'
import Agc from '../images/clients/sentinel-agc.jpg'
const reviews = [
  
    {
        id: 2,
        text: "PragICTS implement Sentinel for AGC across their complete IT infrastructure across all their business locations from HQ, Sales Outlets, Warehouse, Factory, etc. across hardware, software and cloud for total monitoring and actioning.",
        client: 'PragICTS implements Sentinel for AGC',
        date: '20th August 2022',
        image: Agc,
        link: 'https://agc.lk/'
    },
   
]



const AboutUsPage = () => {
    return (
        <Layout pageTitle="News">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                        <div className="scroll-down-wrap">
                    <span>sentinel@pragicts.com</span>
                        <span>+94 11 70 4598 983</span>
                     
                    </div>
                    </div>
                    
        <section  data-scrollax-parent="true" id="testimonials">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >News<span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                   
                    <h2><span>News</span></h2>
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="slider-carousel-wrap text-carousel-wrap fl-wrap">
                <div className="text-carousel-controls fl-wrap">
                    <div className="container">
                        <div className="sp-cont  sp-cont-prev"><i className="fal fa-long-arrow-left"></i></div>
                        <div className="sp-cont   sp-cont-next"><i className="fal fa-long-arrow-right"></i></div>
                    </div>
                </div>
                <div className="text-carousel cur_carousel-slider-container fl-wrap">
                    {reviews.map(review => (
                        <div key={review.id} className="slick-item">
                            <div className="text-carousel-item">
                                { <div className="popup-avatar"><img src={review.image} alt={review.client} title={review.client} /></div> }
                                <div className="listing-rating card-popup-rainingvis" data-starrating2="5"> </div>
                                <div className="review-owner fl-wrap">{review.client} - {review.date} <span></span></div>
                                <p> {review.text}</p>
                                
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>
            
            <div className="sec-lines"></div>
        </section>
    )

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default AboutUsPage
